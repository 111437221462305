.cover-container {
  color: #fff;
  font-family: Jost;
  z-index: 0;
}

.cover-cover {
  z-index: 100;
}

.cover-title {
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
}

@media (min-width: 576px) {
  .cover-title {
    font-size: 57px;
  }
}

.cover-footer {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  z-index: 100;
}

.cover-rectangle {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #00aeef;
  border-radius: 30px 30px 0 0;
  border: solid 1px #00aeef;
  max-width: fit-content;
  text-align: left;
  display: table;
}

.roundBottomCorners {
  border-radius: 30px 30px 30px 30px;
}

.cornerOrange {
  border: solid 1px #ff9f40;
}

.cornerGreen {
  border: solid 1px #46c66d;
}

.cover-text {
  display: table-cell;
  vertical-align: middle;
}

.text-blue {
  color: #1955d5;
}

.text-grey {
  color: #c6c6cc;
}

.background-black {
  background-color: #282828;
}

.background-blue {
  background-color: #1955d5;
}

.background-light-blue {
  background-color: #def5ff;
}

img.Iconsend {
  width: 27px;
  height: 27px;
  margin: 0 0 0 10px;
  object-fit: contain;
  display: table-cell;
  vertical-align: bottom;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
}

.cover-unicef {
  background-color: #00aeef;
  font-family: Jost;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: white;
}

h1,
h2,
p {
  font-family: Jost;
}

.unicef-blue-section-header {
  color: #00aeef;
  font-weight: 600;
}

.chat-bubble {
  padding: 1rem 1rem;
  margin: 0.5rem 0;
  border-radius: 20px;
  background-color: #1955d5;
  color: white;
}

#kindly-status {
  visibility: hidden;
  border-radius: 30px;
  margin-top: 2em;
  padding: 2em 1em;
  border-radius: 30px;
  background-color: #282828;
  font-weight: 600;
  text-align: center;
}

.buttonWrapper {
  display: block;
  border-radius: 23.5px;
  max-width: fit-content;
  padding: 0.5em;
  --original-bg-color: #fff;
  --original-color: #1955d5;
  --kindly-bg-color: #1955d5;
  --kindly-color: #fff;
}

.buttonWrapper.whiteMargin {
  border: solid 1px #fff;
}

.buttonWrapper.blueMargin {
  border: solid 1px #1955d5;
}

.buttonWrapper .btn-check + .btn-outline-light {
  border: 0;
  box-shadow: none;
}

/*.buttonWrapper .btn-check:checked+.btn-outline-light,
.buttonWrapper .btn-check:hover+.btn-outline-light{
  color: var(--btn-color);
}*/

.buttonWrapper .btn-check + .btn-original {
  color: #1955d5;
}

.buttonWrapper .btn-check + .btn-kindly {
  color: #fff;
}

.buttonWrapper .btn-check:checked + .btn-kindly {
  background-color: #1955d5;
  color: #fff;
}

.buttonWrapper .btn-check:hover + .btn-original {
  background-color: var(--original-bg-color);
  color: var(--original-color);
}

.buttonWrapper .btn-check:hover + .btn-kindly {
  background-color: var(--kindly-bg-color);
  color: var(--kindly-color);
}

.buttonWrapper label {
  font-weight: 600;
  font-size: 0.8em;
  padding: 0.6em 2em 0.6em 2em;
}

#contribute-to-kindly .btn-outline-light {
  border: solid 1px #1955d5;
  color: #1955d5;
  box-shadow: none;
}

#contribute-to-kindly .btn-outline-light:hover {
  background-color: #1955d5;
  color: #def5ff;
}

.msgGood {
  background-color: #46c66d;
  color: white;
  border-radius: 0 0 30px 30px;
}

.msgBad {
  background-color: #ff9f40;
  color: white;
  border-radius: 0 0 30px 30px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-link-kindly {
  color: #00aeef;
  text-decoration: none;
  font-weight: 600;
}

#privacy_terms p {
  font-size: 1em;
}

#privacy_terms h2 {
  margin-bottom: 1.2rem;
}
#privacy_terms h3 {
  font-size: 1.3em;
  font-family: Jost;
}

#privacy_terms ul {
  font-size: 1em;
  font-family: Jost;
  margin-bottom: 1em;
}

#privacy_terms aside li {
  text-align: right;
  list-style: none;
}
#privacy_terms aside li a {
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Jost";
  src: local("Jost"), url(./fonts/Jost-VariableFont_wght.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

/* Bootstrap overrides */

.btn {
  border-radius: 17.5px;
}

.btn-primary {
  background-color: #1955d5;
}

.btn-outline-primary {
  border-color: #1955d5;
}

.btn-outline-primary:hover {
  background-color: #1955d5;
}

.btn-small {
  padding: 0.375rem 1.25rem;
  font-size: 0.6em;
  font-weight: 600;
  text-transform: uppercase;
}

.card {
  border-radius: 30px;
  background-color: #def5ff;
}

.cover-rectangle.card {
  background-color: transparent;
  position: absolute;
}

.card-header {
  background-color: rgba(0, 0, 0, 0);
  border-bottom: solid 1px #1955d5;
}

.cover-rectangle .card-header {
  border-bottom: 0;
}

.accordion-item {
  border: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 20px 20px 0 0;
  background-color: #1955d5;
  color: white;
}

.accordion-item:first-of-type .accordion-button.collapsed {
  color: #1955d5;
  background-color: #def5ff;
  border-radius: 20px;
}

.accordion-body {
  background-color: #1955d5;
  color: white;
  border-radius: 0 0 20px 20px;
}

.accordion-body a {
  color: #00aeef;
}

.accordion-button:not(.collapsed),
.accordion-button:focus {
  box-shadow: none;
}

.accordion-button::after {
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2228%22%20height%3D%2227%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M.5%200h27v27H.5z%22/%3E%0A%20%20%20%20%20%20%20%20%3Cg%20stroke%3D%22%231955D5%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M14%207.08v12.84M20.42%2013.5H7.58%22/%3E%0A%20%20%20%20%20%20%20%20%3C/g%3E%0A%20%20%20%20%3C/g%3E%0A%3C/svg%3E);
}
.accordion-button:not(.collapsed)::after {
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2228%22%20height%3D%2227%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M.5%200h27v27H.5z%22/%3E%0A%20%20%20%20%20%20%20%20%3Cg%20stroke%3D%22%23FFF%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M14%207.08v12.84M20.42%2013.5H7.58%22/%3E%0A%20%20%20%20%20%20%20%20%3C/g%3E%0A%20%20%20%20%3C/g%3E%0A%3C/svg%3E);
}

.lead {
  font-weight: 600;
}
